import { Link, LinkBaseProps } from '@mui/material';
import themes from '~/themes';

interface LinkButtonProps extends LinkBaseProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: any) => void;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { children, id, type = 'button', onClick, ...rest } = props;
  return (
    <Link
      {...rest}
      type={type}
      data-testid={`${
        typeof children === 'string'
          ? children?.toString()?.replace(' ', '-')?.toLowerCase()
          : id
      }-link-button`}
      color={themes.color.black}
      textAlign='left'
      component='button'
      variant='body1'
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

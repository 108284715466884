import { PENDO_KEY } from '~/config';
import { IAccount } from '~/models/adminOptions';
import { IOrg } from '~/models/org';
import { IPlan, IPlanTier } from '~/models/plan';

export const initializePendo = (
  account: IAccount,
  org: IOrg,
  currentPlan: IPlan,
) => {
  if (!account?.id) return;
  const { id, email, display_name, role } = account;
  const { id: orgId, name: orgName, created_at: createdOrgAt, tier } = org;
  const isPaying = tier !== IPlanTier.Free;
  const { name, unit_amount } = currentPlan;
  window.pendo.initialize({
    visitor: {
      id, // Required if user is logged in, default creates anonymous ID
      email, // Recommended if using Pendo Feedback, or NPS Email
      full_name: display_name, // Recommended if using Pendo Feedback
      role, // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: orgId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name: orgName, // Optional
      creationDate: createdOrgAt, // Optional
      is_paying: isPaying, // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      ...(currentPlan && {
        planLevel: name, // Optional
        planPrice: unit_amount, // Optional
      }),

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};

export const addPendoScript = () => {
  if (!PENDO_KEY) return;
  const pendoScript = document.createElement('script');
  pendoScript.text = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('${PENDO_KEY}');`;
  document.head.appendChild(pendoScript);
};

import { ClassNameMap, WithStyles, withStyles } from '@mui/styles';
import React from 'react';
import clsx from 'clsx';
import {
  FormControlLabel,
  FormControlLabelClassKey,
  FormLabel,
  Switch,
  SwitchProps,
} from '@mui/material';
import style from './style';

export interface ISwitchButton extends SwitchProps {
  className?: string;
  noText?: boolean;
  label?: string;
  formClasses?: Partial<ClassNameMap<FormControlLabelClassKey>>;
  legend?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const SwitchButton: React.FC<ISwitchButton & WithStyles<typeof style>> = ({
  classes,
  noText = false,
  label = '',
  formClasses = {},
  legend,
  id,
  name,
  inputProps,
  ...props
}) => (
  <>
    {legend && (
      <FormLabel className={classes.legend} component='legend'>
        {legend}
      </FormLabel>
    )}
    <FormControlLabel
      classes={{
        root: classes.formRoot,
        label: classes.formLabel,
        ...formClasses,
      }}
      control={
        <Switch
          {...props}
          inputProps={
            {
              'data-testid': `${id || name}-switch-button`,
              ...inputProps,
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
          disableRipple
          classes={{
            root: clsx(classes.root, {
              [classes.rootNoText]: noText,
            }),
            switchBase: clsx(classes.switchBase, {
              [classes.switchBaseNoText]: noText,
            }),
            thumb: classes.thumb,
            track: clsx(classes.track, {
              [classes.trackNoText]: noText,
            }),
            checked: classes.checked,
            disabled: classes.disabled,
          }}
        />
      }
      label={label}
    />
  </>
);

export default withStyles(style)(SwitchButton);

import {
  Select as MuiSelect,
  FormHelperText,
  FormLabel,
  MenuItem,
  FormControl,
  Typography,
  FormControlProps,
  SxProps,
} from '@mui/material';
import themes from '~/themes';
import { BaseSelectProps as _SelectProps } from '@mui/material/Select';
import { KeyboardArrowDown } from '@mui/icons-material';

type SelectOptionProps = {
  label?: string | any;
  value?: string | any;
};

export interface SelectProps extends _SelectProps {
  legend?: React.ReactNode | string;
  value?: any;
  options: Array<SelectOptionProps>;
  helperText?: string;
  error?: boolean;
  fullWidth?: boolean;
  noOptionsText?: string;
  placeholder?: string;
  sxPlaceholder?: SxProps;
  popupWidthFixed?: number;
  paperMaxHeight?: number;
  renderValue?: (value: any) => React.ReactNode;
  renderOption?: (value: any) => React.ReactNode;
  formControlProps?: FormControlProps;
}

export const Select: React.FC<SelectProps> = ({
  label,
  legend,
  value,
  children,
  options,
  error = false,
  helperText,
  fullWidth = true,
  noOptionsText,
  placeholder,
  popupWidthFixed,
  formControlProps,
  paperMaxHeight,
  id,
  sxPlaceholder,
  renderValue,
  renderOption,
  ...rest
}) => {
  const renderOptions = () => {
    if (!options.length)
      return (
        <Typography
          color={themes.color.gray600}
          data-testid={`${id}-no-select-option`}
        >
          {noOptionsText || 'No options'}
        </Typography>
      );
    return options.map((option: SelectOptionProps) => {
      if (renderOption) {
        return renderOption(option);
      }
      return (
        <MenuItem
          key={option?.value}
          value={option?.value || ''}
          data-testid={`${id}-select-option`}
          disableRipple
          sx={{
            'color': themes.color.black,
            'whiteSpace': 'normal !important',
            'textOverflow': 'ellipsis',
            'overflow': 'hidden',
            'borderRadius': '15px',
            '&.MuiMenuItem-gutters': {
              '&:hover': {
                background: `${themes.bg.gray300}`,
              },
            },
            '&.Mui-selected': {
              'backgroundColor': themes.bg.midPurple,
              '&:focus': {
                backgroundColor: themes.bg.midPurple,
              },
            },
          }}
        >
          {option?.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth={fullWidth} {...formControlProps}>
      {legend && (
        <FormLabel>{typeof legend === 'string' ? legend : legend}</FormLabel>
      )}
      <MuiSelect
        {...rest}
        value={value || ''}
        data-value={value ? `${value}-select` : ''}
        data-testid={`${id}-select`}
        error={error}
        displayEmpty
        IconComponent={(props) => (
          <KeyboardArrowDown
            {...props}
            sx={{ color: `${themes.color.violet900} !important` }}
          />
        )}
        renderValue={
          renderValue ||
          ((val) => {
            if (!value && placeholder) {
              return (
                <Typography
                  color={themes.color.black}
                  sx={{ lineHeight: 1, ...sxPlaceholder }}
                >
                  {placeholder}
                </Typography>
              );
            }
            const l = options.find((o) => o.value === val)?.label || '';
            return l;
          })
        }
        MenuProps={{
          MenuListProps: {
            style: {
              padding: '10px',
              ...(popupWidthFixed && {
                maxWidth: popupWidthFixed,
                minWidth: popupWidthFixed,
              }),
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            className: 'customized-scrollbar',
            style: {
              boxShadow: '0px 1px 6px #160E4D14',
              borderRadius: '15px',
              opacity: '1',
              maxHeight: paperMaxHeight || 'calc(100% - 96px)',
            },
          },
        }}
      >
        {children || renderOptions()}
      </MuiSelect>
      {helperText ? (
        <FormHelperText
          data-testid={`${id}-select-error`}
          error={error}
          sx={{ marginLeft: 0, marginRight: 0 }}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

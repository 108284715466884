import clsx from 'clsx';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Dialog, DialogProps, SxProps } from '@mui/material';
import { useMemo } from 'react';
import Button, { CloseButton } from '../Button';
import style from './style';

export interface IProps
  extends WithStyles<typeof style>,
    Omit<DialogProps, 'classes' | 'title'> {
  className?: string;
  children?: React.ReactNode;
  customContentModal?: any;
  title?: React.ReactNode;
  actions?: any;
  customActions?: any;
  labelComponent?: React.ReactNode;
  dividers?: boolean;
  customTitle?: any;
  subTitle?: string | React.ReactNode;
  disableCloseOutside?: boolean;
  rootClassName?: string;
  closeButtonStyles?: {
    iconSize?: number;
    background?: string;
    sx?: SxProps;
  };
  onClose: () => void;
}

const Modal: React.FC<IProps> = ({
  className = '',
  classes,
  children,
  customContentModal,
  title,
  actions = [],
  customActions = [],
  fullWidth,
  labelComponent,
  dividers = false,
  customTitle,
  subTitle,
  disableCloseOutside,
  rootClassName,
  closeButtonStyles,
  onClose,
  ...rest
}) => {
  const customActionsComponent = useMemo(
    () => (
      <MuiDialogActions
        sx={{
          borderTop: '1px solid #E9E7F6',
          px: 3,
          py: 2,
        }}
      >
        {customActions &&
          customActions.map((action: any) => (
            <Button
              key={`key-${action.title}`}
              onClick={action.onClick}
              rounder10
              {...action}
            >
              {action.title}
            </Button>
          ))}
      </MuiDialogActions>
    ),
    [customActions],
  );
  return (
    <Dialog
      classes={{
        paper: clsx(classes.dialog, {
          [className]: Boolean(className),
        }),
        root: clsx(classes.rootDialog, rootClassName),
      }}
      fullWidth
      onClose={() => !disableCloseOutside && onClose()}
      aria-labelledby='customized-dialog-title'
      PaperProps={{
        id: 'paper-modal',
      }}
      {...rest}
    >
      {title && (
        <MuiDialogTitle
          data-testid='title-modal'
          className={clsx(classes.title, customTitle)}
        >
          <div className={classes.titleContainer}>
            <Typography variant='h6'>{title}</Typography>
            {Boolean(labelComponent) && labelComponent}
          </div>
          {Boolean(subTitle) && (
            <div className={classes.subTitle}>{subTitle}</div>
          )}
          {onClose ? (
            <CloseButton
              {...closeButtonStyles}
              className={classes.closeButton}
              onClick={onClose}
            />
          ) : null}
        </MuiDialogTitle>
      )}
      <MuiDialogContent
        data-testid='content-modal'
        className={clsx(
          classes.content,
          customContentModal,
          'customized-scrollbar',
        )}
        dividers={dividers}
      >
        {children}
      </MuiDialogContent>
      {actions.length > 0 && (
        <MuiDialogActions
          classes={{
            root: classes.actions,
          }}
        >
          <div className={classes.containerActions}>
            {actions &&
              actions.map((action: any, index: number) => (
                <Button
                  className={clsx(classes.button, {
                    [classes.fullButton]: action.fullButton,
                    [action.className]: Boolean(action.className),
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`key ${action.title} ${index}`}
                  onClick={action.onClick}
                  {...action}
                >
                  {action.title}
                </Button>
              ))}
          </div>
        </MuiDialogActions>
      )}
      {customActions.length > 0 && customActionsComponent}
    </Dialog>
  );
};

export default withStyles(style)(Modal);

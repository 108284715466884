import React, { useCallback, useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Table, { TColumn } from '~/components/common/Table/Table';
import themes, { styled } from '~/themes';
import {
  Task as ITask,
  LocationInfo as ITaskLocation,
  TaskStatus,
} from '~/pages/LiveTracking/types';
import { convertedSortParams } from '~/utils/common';
import {
  formatDateTimeUTC,
  formatTaskDateTime,
  convertShortString,
} from '~/utils/formatter';

import InfoIcon from '~/assets/images/icons/info.svg';
import Tag from '~/components/common/Tag';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';
import { Rating } from '~/components/common/Rating';
import { ITabValue } from '~/models/shared';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import BackgroundImage from '~/assets/images/background/setup-task.png';
import clsx from 'clsx';
import moment from 'moment';

const EmptyStateData = (tabValue: ITabValue) => [
  {
    name: 'C-1010',
    status: tabValue === ITabValue.Completed ? 'completed' : 'delivering',
    expected_delivery_after: moment().set({ hour: 13, minute: 0, second: 0 }),
    expected_delivery_before: moment().set({ hour: 14, minute: 0, second: 0 }),
    executor: {
      display_name: 'Jane Doe',
    },
    pickup: {
      address_1: '1010 Kingsway',
      city: 'Burnaby',
      state: 'BC',
      postcode: 'V0H 0B0',
    },
    delivery: {
      address_1: '1010 W Georgia St',
      city: 'Vancouver',
      state: 'BC',
      postcode: 'V0Y 0G0',
    },
    rating: 3,
    updated_at: moment()
      .subtract({ day: 2 })
      .set({ hour: 10, minute: 10, second: 0 }),
  },
  { id: '_noData' },
];

export const StyledTaskId = styled(Stack)(({ theme }) => ({
  'flexDirection': 'row',
  'alignItems': 'center',
  '& > span': {
    marginRight: theme.spacing(0.5),
  },
}));

export const StyledAllTaskCompletedContainer = styled('div')(({ theme }) => ({
  'position': 'absolute',
  'display': 'flex',
  'flexDirection': 'column',
  'alignItems': 'center',
  'justifyContent': 'center',
  'padding': theme.spacing(2),
  'height': `calc(100% - ${theme.spacing(30)})`,
  'width': `calc(100% - ${theme.spacing(5)})`,
  'zIndex': 2,
  '& > h1': {
    marginTop: theme.spacing(3),
    fontSize: 14,
    paddingTop: 0,
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    '& > img:first-of-type': {
      maxWidth: '100%',
    },
  },
}));

interface ITaskTableProps {
  data?: ITask[];
  isFullEmpty?: boolean;
  isLoading?: boolean;
  handleSort: (sortId: string, nextArrange: number) => void;
  isEmptyState?: boolean;
  renderEmpty?: React.ReactNode;
}

export const TaskTable: React.FC<ITaskTableProps> = ({
  data = [],
  isLoading,
  handleSort,
  isFullEmpty,
  isEmptyState,
  renderEmpty,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isStarterPlan } = usePaymentInfo();

  const { id: taskId, sort, sort_by, tab } = fromQueryString(location.search);
  const tabValue = (tab || ITabValue.Ongoing) as ITabValue;
  const dateFormats = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    nextWeek: 'MM/DD/YYYY',
    lastWeek: 'MM/DD/YYYY',
    sameElse: 'MM/DD/YYYY',
  };

  const onRowClick = useCallback(
    (item: { id: string }) => {
      let search;
      if (item.id === taskId) {
        search = omitFromQueryString(location.search, ['id']);
      } else {
        search = addToQueryString(location.search, {
          id: item.id,
        });
      }
      navigate({
        pathname: location.pathname,
        search,
      });
    },
    [location],
  );

  const clickableRowId = useMemo(
    () => taskId && data.findIndex((x) => x.id === taskId),
    [data, taskId],
  );

  const renderTaskId = useCallback(
    (item: ITask) => (
      <StyledTaskId>
        <span data-testid={`task-id-${item.name}`}>{item.name}</span>
        {item.note && (
          <Tooltip title={item.note}>
            <img width={20} height={20} src={InfoIcon} alt='info' />
          </Tooltip>
        )}
      </StyledTaskId>
    ),
    [],
  );

  const renderPickup = useCallback(
    (item: ITask) => {
      const { status, skip_pickup } = item;
      if (skip_pickup) return '-';

      if (
        status === TaskStatus.PendingPickup ||
        status === TaskStatus.Unassigned
      )
        return 'ASAP';

      if (status === TaskStatus.Delivering || status === TaskStatus.Returning)
        return item.picked_up_at ? (
          <>
            <div>{formatDateTimeUTC(item.picked_up_at)}</div>
            <div>{formatTaskDateTime(item.picked_up_at, dateFormats)}</div>
          </>
        ) : (
          '-'
        );

      return '';
    },
    [isStarterPlan, isFullEmpty],
  );

  const renderDeliveryBefore = useCallback(
    (item: ITask) => {
      const {
        status,
        expected_delivery_after,
        expected_delivery_before,
        is_active,
        estimated_delivery_time,
        delivery_arr_time,
      } = item;

      if (
        status === TaskStatus.PendingPickup ||
        status === TaskStatus.Unassigned
      ) {
        if (!expected_delivery_after && !expected_delivery_before)
          return 'ASAP';
        if (!expected_delivery_after && expected_delivery_before)
          return (
            <>
              <div>ASAP</div>
              <div>
                {formatTaskDateTime(expected_delivery_before, dateFormats)}
              </div>
            </>
          );
        return (
          <>
            <div>{formatDateTimeUTC(expected_delivery_before)}</div>
            <div>
              {formatTaskDateTime(expected_delivery_before, dateFormats)}
            </div>
          </>
        );
      }

      if (status === TaskStatus.Returning) return 'ASAP';
      if (status === TaskStatus.Delivering) {
        const formatDate = {
          sameDay: 'hh:mm a[\n][Today]',
          nextDay: 'hh:mm a[\n][Tomorrow]',
          lastDay: 'hh:mm a[\n][Yesterday]',
          nextWeek: 'hh:mm a[\n]MM/DD/YYYY',
          lastWeek: 'hh:mm a[\n]MM/DD/YYYY',
          sameElse: 'hh:mm a[\n]MM/DD/YYYY',
        };
        if (is_active)
          return estimated_delivery_time ? (
            <div style={{ whiteSpace: 'pre' }}>
              {moment()
                .add(estimated_delivery_time, 'm')
                .calendar(null, formatDate)}
            </div>
          ) : (
            '-'
          );
        return delivery_arr_time ? (
          <div style={{ whiteSpace: 'pre' }}>
            {moment().add(delivery_arr_time, 's').calendar(null, formatDate)}
          </div>
        ) : (
          '-'
        );
      }

      return '';
    },
    [isStarterPlan, isFullEmpty],
  );

  const renderDriver = useCallback((item: ITask) => {
    if (item.executor) {
      const maxLength = 22;
      const isShowTooltip = item?.executor?.display_name?.length > maxLength;
      return (
        <Tooltip title={isShowTooltip ? item.executor.display_name : ''}>
          <span>
            {convertShortString(item?.executor?.display_name || '', maxLength)}
          </span>
        </Tooltip>
      );
    }
    return 'Unassigned';
  }, []);

  const renderStatus = useCallback(
    (item: ITask) => (
      <Tag
        size='small'
        style={{ width: '120px' }}
        tag={item.status}
        sx={{
          ...(isFullEmpty
            ? {
                background: 'rgba(0,0,0,0.1) !important',
                color: 'rgba(0,0,0,0.8) !important',
              }
            : {}),
        }}
      />
    ),
    [isFullEmpty],
  );

  const renderLocation = useCallback(
    (taskLocation: ITaskLocation, customerName?: string) => {
      if (taskLocation) {
        const maxLength = 30;
        const locationLine1 = taskLocation?.address_1;
        const locationLine2 = `${taskLocation.city}, ${taskLocation.state} ${taskLocation.postcode}`;
        const isShowTooltip =
          locationLine1.length > maxLength ||
          locationLine2.length > maxLength ||
          customerName?.length > maxLength;

        return (
          <Tooltip
            title={
              isShowTooltip ? (
                <>
                  {customerName && <div>{customerName}</div>}
                  <div>{locationLine1}</div>
                  <div>{locationLine2}</div>
                </>
              ) : (
                ''
              )
            }
          >
            <div>
              {customerName && (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all',
                  }}
                >
                  {customerName}
                </Typography>
              )}
              <div>{convertShortString(locationLine1, maxLength)}</div>
              <div>{convertShortString(locationLine2, maxLength)}</div>
            </div>
          </Tooltip>
        );
      }
      return '-';
    },
    [],
  );

  const renderUpdateTime = useCallback(
    (item: ITask) => {
      if (isFullEmpty) {
        return (
          <>
            10:10 pm
            <br />
            10/10/2023
          </>
        );
      }
      return (
        <>
          <div>{formatDateTimeUTC(item.updated_at)}</div>
          <div>{formatTaskDateTime(item.updated_at, dateFormats)}</div>
        </>
      );
    },
    [isFullEmpty],
  );

  const renderDeliveryTime = useCallback((item: ITask) => {
    const { status, completed_at, returned_at, delivered_at } = item;

    let time = '';
    if (status === TaskStatus.Returned) {
      time = returned_at;
    }
    if (status === TaskStatus.Completed) {
      time = delivered_at;
    }
    if (status === TaskStatus.Cancelled) {
      time = completed_at;
    }

    return (
      <>
        <div>{formatDateTimeUTC(time)}</div>
        <div>{formatTaskDateTime(time, dateFormats)}</div>
      </>
    );
  }, []);

  const renderScheduledDeliveryTime = useCallback(
    (item: ITask) => {
      if (isFullEmpty) {
        return '1:00 pm - 2:00 pm Today';
      }
      if (isStarterPlan) {
        return '-';
      }
      const { expected_delivery_after, expected_delivery_before } = item;
      if (!expected_delivery_after && !expected_delivery_before) {
        return 'ASAP';
      }
      if (!expected_delivery_after && expected_delivery_before) {
        return (
          <>
            <div>ASAP</div>
            <div>
              {formatTaskDateTime(expected_delivery_before, dateFormats)}
            </div>
          </>
        );
      }

      return (
        <>
          <div>{`${formatDateTimeUTC(
            expected_delivery_after,
          )} - ${formatDateTimeUTC(expected_delivery_before)}`}</div>
          <div>{formatTaskDateTime(expected_delivery_before, dateFormats)}</div>
        </>
      );
    },
    [isStarterPlan, isFullEmpty],
  );

  const columns: TColumn[] = useMemo(() => {
    const d = [
      {
        id: 'integration_type',
        title: 'Task Number',
        sortable: true,
        renderComponent: renderTaskId,
        cellStyle: {
          height: 41,
          width: 120,
        },
      },
      {
        id: 'status',
        title: 'Status',
        sortable: true,
        cellStyle: { width: 120 },
        renderComponent: renderStatus,
      },
      {
        id: 'expected_delivery_after',
        title: 'Scheduled Delivery Time',
        sortable: !isStarterPlan,
        cellStyle: { maxWidth: 180 },
        renderComponent: renderScheduledDeliveryTime,
      },
      {
        id: 'delivery_time',
        title: 'Delivery time',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderDeliveryTime,
      },
      {
        id: 'executor_id',
        title: 'Driver',
        sortable: true,
        cellStyle: { maxWidth: 120 },
        renderComponent: renderDriver,
      },
      {
        id: 'delivery_name',
        title: 'Pickup Location',
        sortable: true,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 170,
        },
        renderComponent: (item) => renderLocation(item.pickup),
      },
      {
        id: 'picked_up_at',
        title: 'Pickup',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderPickup,
      },
      {
        id: 'delivery_address',
        title: 'Dropoff Location',
        sortable: true,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 170,
        },
        renderComponent: (item) =>
          renderLocation(item.delivery, item.delivery?.name),
      },
      {
        id: 'delivery_arr_time',
        title: 'Delivery Before',
        cellStyle: { maxWidth: 180 },
        renderComponent: renderDeliveryBefore,
      },
      {
        id: 'rating',
        title: 'Rating',
        sortable: !isStarterPlan,
        cellStyle: {
          width: 100,
        },
        renderComponent: (item) => {
          if (isStarterPlan && !isFullEmpty) {
            return '-';
          }
          if (item?.rating) {
            return <Rating value={item?.rating} readOnly />;
          }
          return '-';
        },
      },
      {
        id: 'updated_at',
        title: 'Last Updated',
        sortable: true,
        cellStyle: {
          maxWidth: 150,
          textAlign: 'right',
        },
        renderComponent: renderUpdateTime,
      },
    ];
    if (tabValue === ITabValue.Ongoing) {
      return d.filter((c) => !['rating', 'delivery_time'].includes(c.id));
    }

    return d.filter(
      (c) =>
        ![
          'picked_up_at',
          'delivery_arr_time',
          'expected_delivery_after',
        ].includes(c.id),
    );
  }, [tabValue, isStarterPlan, isFullEmpty]);

  const isAllTaskOngoingDone = tabValue === ITabValue.Ongoing && isEmptyState;

  const renderTableEmptyState = useMemo(() => {
    if (isFullEmpty) {
      return (
        <StyledAllTaskCompletedContainer>
          {renderEmpty}
        </StyledAllTaskCompletedContainer>
      );
    }
    if (isAllTaskOngoingDone) {
      return (
        <StyledAllTaskCompletedContainer>
          <img width={400} src={BackgroundImage} alt='setup-your-store' />
          <Typography variant='h1'>All tasks are completed!</Typography>
        </StyledAllTaskCompletedContainer>
      );
    }
    return null;
  }, [tabValue, isAllTaskOngoingDone, isFullEmpty, renderEmpty]);

  return (
    <Table
      customClassName={clsx({
        ongoing: tabValue === ITabValue.Ongoing,
        completed: tabValue === ITabValue.Completed,
      })}
      containerSxProps={{
        ...(isFullEmpty
          ? {
              '& td': {
                filter: 'grayscale(1)',
                opacity: 0.25,
              },
            }
          : {}),
        [themes.breakpoints.down('md')]: {
          '& table': {
            '& .MuiChip-root': {
              width: '100px !important',
            },
          },
          // '&.ongoing': {
          //   '& td, th': {
          //     '&:nth-of-type(3)': {
          //       display: 'none',
          //     },
          //     '&:nth-of-type(n + 5)': {
          //       display: 'none',
          //     },
          //   },
          // },
          // '&.completed': {
          //   '& td, th': {
          //     '&:nth-of-type(n + 4)': {
          //       display: 'none',
          //     },
          //   },
          // },
        },
      }}
      columns={columns}
      dataProps={isFullEmpty ? EmptyStateData(tabValue) : data}
      sortBy={convertedSortParams(sort, sort_by)}
      onSort={handleSort}
      clickableRowId={clickableRowId}
      onRowClick={onRowClick}
      striped={false}
      noResultText={null}
      customEmptyState={renderTableEmptyState}
      loading={isLoading}
      sxNoData={{ padding: '35px !important' }}
    />
  );
};

import React from 'react';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { COUNTRY_CODES } from '~/constants/task';
import 'react-phone-input-2/lib/style.css';
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  BaseSelectProps,
} from '@mui/material';
import { Theme } from '~/themes';
import clsx from 'clsx';

const style = (theme: Theme) =>
  createStyles({
    countryCode: {
      'width': 95,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    option: {
      borderRadius: 15,
      padding: theme.spacing(0.5, 0.6),
    },
    paper: {
      boxShadow: '0px 2px 10px #D6DEF2',
      borderRadius: 15,
      color: theme.color.black,
    },
    item: {
      'borderRadius': 18,
      '&:not(:first-child)': {
        marginTop: 0.5,
      },
    },
    itemSelected: {
      backgroundColor: `${theme.bg.midPurple} !important`,
    },
  });

export interface ICountryCodeDropdown extends Omit<BaseSelectProps, 'classes'> {
  legend?: string;
}

const CountryCodeDropdown: React.FC<
  ICountryCodeDropdown & WithStyles<typeof style>
> = ({ value, onChange, legend, classes, name, className, ...props }) => (
  <FormControl>
    {legend && <FormLabel component='legend'>{legend}</FormLabel>}
    <Select
      className={clsx(classes.countryCode, className)}
      labelId={name}
      id={name}
      value={value}
      label='Age'
      onChange={onChange}
      MenuProps={{
        MenuListProps: {
          className: classes.option,
        },
        PaperProps: {
          className: classes.paper,
        },
      }}
      {...props}
    >
      {COUNTRY_CODES.map((item) => (
        <MenuItem
          key={item.value}
          classes={{
            root: classes.item,
            selected: classes.itemSelected,
          }}
          value={item.value}
        >
          <div className='react-tel-input'>
            <div className={`flag ${`${item.value}`.toLowerCase()}`} />{' '}
            {`+${item.code}`}
          </div>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default withStyles(style)(CountryCodeDropdown);

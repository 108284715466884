import { Box, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import themes, { Theme } from '~/themes';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';
import Table from '~/components/common/Table';
import { useCallback, useMemo, useState } from 'react';
import { TColumn } from '~/components/common/Table/Table';
import NotConnectedIcon from '~/assets/images/icons/not-connected.svg';
import ConnectedIcon from '~/assets/images/icons/connected.svg';
import { IntegrationStatus, IntegrationType } from '~/models/stores';
import { useGetListIntegrationQuery } from '~/services/api/stores';
import { SearchFormik } from '~/components/common/Search';
import Button from '~/components/common/Button';
import CircularLoading from '~/components/common/CirularLoading';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { convertedSortParams } from '~/utils/common';
import SetupYourStore from '~/assets/images/background/setup-your-store.svg';
import BreadstackLogo from '~/assets/images/icons/breadstack-logo.svg';
import WooLogo from '~/assets/images/icons/woocommerce.svg';
import { useFirstLoadingDetection } from '~/hooks';
import { DEFAULT_DOWN_SIZE } from '~/constants/common';
import { StyledPanelDetailContainer } from '~/themes/style';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { getPlatformIcon } from '~/utils/settings';
import { ToolTipText } from '~/components/common/Tooltip/TooltipText';
import { deliveryHoursDefaultData } from '~/constants/stores';
import {
  StyledConnectedIcon,
  StyledPlatformIcon,
  StyledStatusText,
} from '../style';
import { StoreDetail } from '../Detail/StoreDetail';
import { AddStore } from '../AddStore/AddStore';
import { StyledFilterContainer } from '../../style';

const EmptyStateData = [
  {
    display_name: 'My Delivery Business INC.',
    integration_type: 'shopify',
    store_url: 'http://myonlinewebsite.com/',
    status: 'active',
    org_id: '64489c4244de8f93841ef993',
    role: 'api_key',
    default: false,
    connected: true,
    store_id: '63e49919785f0b9f69d6b973',
    breadstack_url:
      'https://int-v2.breadstackcrm.com/s/63e49919785f0b9f69d6b973/admin-options/warehouse',
    number_of_delivery_zones: 1,
    enable_delivery: true,
    id: '651399e518510eceb1e160a4',
    number_of_teams: 1,
  },
  {
    id: '_noData',
  },
];

const style = (theme: Theme) =>
  createStyles({
    loading: {
      maxHeight: 'unset',
    },
    setupYourStore: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: themes.spacing(2),
      marginTop: theme.spacing(2),
    },
    filterContainer: {
      'display': 'flex',
      'flexWrap': 'wrap',
      'gap': theme.spacing(1, 2),
      'marginBottom': theme.spacing(1),
      '& > *': {
        '&:last-child': {
          marginLeft: 'auto',
        },
      },
      '& > form': {
        minWidth: 300,
      },
    },
    [theme.breakpoints.down(DEFAULT_DOWN_SIZE)]: {
      setupYourStore: {
        'marginTop': theme.spacing(1),
        'maxWidth': '100%',
        '& > img:first-child': {
          maxWidth: '100%',
        },
      },
      filterContainer: {
        'gap': theme.spacing(1),
        '& > form': {
          minWidth: 0,
          display: 'flex',
          flex: 1,
        },
      },
    },
  });

interface IStoreList extends WithStyles<typeof style> {}

const StoreList: React.FC<IStoreList> = ({ classes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenAddStore, setIsOpenAddStore] = useState(false);

  const { isStarterOrStandardPlan } = usePaymentInfo();

  const {
    id: storeId,
    sort,
    sort_by,
    search,
  } = fromQueryString(location.search);

  const { data: rawData, isFetching } = useGetListIntegrationQuery({
    search,
    sort,
    sort_by,
  });

  const firstLoading = useFirstLoadingDetection([isFetching]);
  const data = useMemo(() => rawData || [], [rawData]);

  const migrateHours = (deliveryHours) => {
    const newDeliveryHours = {};
    const weekDays = Object.keys(deliveryHours);
    weekDays.forEach((wd) => {
      const hours = deliveryHours[wd];
      newDeliveryHours[wd] = { ...hours };
    });
    return newDeliveryHours;
  };

  const storeData: any = useMemo(() => {
    if (!data.length) {
      return {};
    }
    const store = data.find((d) => d.id === storeId);

    if (
      store?.delivery?.delivery_hours &&
      store?.integration_type === IntegrationType.Woo
    ) {
      store.delivery.delivery_hours =
        migrateHours(store.delivery.delivery_hours) || deliveryHoursDefaultData;

      store.delivery.special_hours =
        store.delivery?.special_hours.map((s) => ({
          ...s,
          ...(s?.delivery_hours ? migrateHours(s?.delivery_hours) : null),
        })) || [];
    }

    return store || {};
  }, [data, storeId]);

  const isEmptyState =
    !rawData?.length && !isFetching && !location.search.length;

  const onRowClick = useCallback(
    (item: any) => {
      let searchData;
      if (item.id === storeId) {
        searchData = omitFromQueryString(location.search, ['id', 'platform']);
      } else {
        searchData = addToQueryString(location.search, {
          id: item.id,
          platform: item.integration_type,
        });
      }
      navigate({
        pathname: location.pathname,
        search: searchData,
      });
    },
    [location],
  );

  const clickableRowId = useMemo(
    () => storeId && data.findIndex((x) => x.id === storeId),
    [data, storeId],
  );

  const handleSort = useCallback(
    (sortId: string, nextArrange: number) => {
      navigate({
        pathname: location.pathname,
        search: addToQueryString(location.search, {
          sort: nextArrange,
          sort_by: sortId,
        }),
      });
    },
    [location],
  );

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'display_name',
        title: 'Store',
        cellStyle: { width: 200 },
        sortable: true,
        renderComponent: (item) => (
          <ToolTipText
            text={item?.display_name}
            maxLength={35}
            textNode={(v) => (
              <Typography
                noWrap
                data-testid='store-display-name-table'
                data-value={item.display_name}
              >
                {v || '-'}
              </Typography>
            )}
          />
        ),
      },
      {
        id: 'platform',
        title: 'Platform',
        sortable: false,
        renderComponent: (item) =>
          isEmptyState ? (
            <Box
              sx={{
                width: 34,
                height: 34,
                borderRadius: 17,
                backgroundColor: 'rgba(0,0,0,0.1)',
              }}
            />
          ) : (
            <StyledPlatformIcon
              alt='platform-icon'
              src={getPlatformIcon(item.integration_type)}
            />
          ),
      },
      {
        id: 'store_url',
        title: 'Store URL',
        sortable: false,
        cellStyle: { width: 250 },
        renderComponent: (item) => (
          <ToolTipText
            text={item?.store_url}
            maxLength={30}
            textNode={(v) => (
              <a
                href={item?.store_url}
                target='_blank'
                rel='noreferrer'
                data-testid='store-url-table'
              >
                {v || '-'}
              </a>
            )}
          />
        ),
      },
      {
        id: 'e_integration',
        title: 'eCommerce Integration',
        sortable: false,
        renderComponent: (item) => {
          const isEIntegration = item.status === IntegrationStatus.Active;
          return (
            <StyledStatusText enabled={isEIntegration}>
              {isEIntegration ? 'Enabled' : 'Disabled'}
            </StyledStatusText>
          );
        },
      },
      {
        id: 'connected',
        title: 'Plug-in Connection',
        sortable: false,
        renderComponent: (item) => (
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <StyledConnectedIcon
              alt='connection-icon'
              src={item.connected ? ConnectedIcon : NotConnectedIcon}
            />
            <StyledStatusText
              enabled={item.connected}
              data-testid='store-connection'
            >
              {item.connected ? 'Connected' : 'Not Connected'}
            </StyledStatusText>
          </Stack>
        ),
      },
      {
        id: 'teams',
        title: 'Teams',
        sortable: false,
        renderComponent: (item) => {
          if (isStarterOrStandardPlan && !isEmptyState) {
            return '-';
          }

          if (item.number_of_teams) {
            return `${item.number_of_teams} ${
              item.number_of_teams > 1 ? 'teams' : 'team'
            }`;
          }
          return '-';
        },
      },
      {
        id: 'delivery_schedule',
        title: 'Delivery Hours',
        sortable: false,
        renderComponent: (item) => (
          <StyledStatusText enabled={item.enable_delivery}>
            {item.enable_delivery ? 'Enabled' : 'Disabled'}
          </StyledStatusText>
        ),
      },
      {
        id: 'delivery_zones',
        title: 'Delivery Zones',
        sortable: false,
        renderComponent: (item) =>
          item.number_of_delivery_zones
            ? `${item.number_of_delivery_zones} ${
                item.number_of_delivery_zones > 1 ? 'zones' : 'zone'
              }`
            : '-',
      },
    ],
    [isStarterOrStandardPlan, isEmptyState],
  );

  const headerComponent = useMemo(
    () => (
      <StyledFilterContainer>
        <SearchFormik placeholder='Search' clearIds={['id', 'platform']} />
        <Button buttonType='primary' onClick={() => setIsOpenAddStore(true)}>
          Add Store
        </Button>
      </StyledFilterContainer>
    ),
    [],
  );

  const renderSetupStore = () => (
    <Stack className={classes.setupYourStore}>
      <img
        src={SetupYourStore}
        alt='setup-your-store'
        style={{ maxHeight: 200 }}
      />
      <Typography
        variant='h1'
        sx={{
          fontSize: 22,
        }}
      >
        Set up your store
      </Typography>
      <Typography
        sx={{
          color: themes.color.violet900,
          textAlign: 'center',
          marginTop: themes.spacing(1),
          whiteSpace: 'break-spaces',
        }}
      >
        Sync Breadstack Delivery with your store’s ecommerce platform to create
        an automated flow
        <br />
        of delivery tasks and simplify management.
      </Typography>
      <Stack spacing={2} mt={2} direction='row'>
        <img src={BreadstackLogo} alt='breadstack' />
        <img src={WooLogo} alt='woo' />
      </Stack>
      <Button
        sx={{ marginTop: themes.spacing(3) }}
        buttonType='primary'
        onClick={() => setIsOpenAddStore(true)}
      >
        Connect eCommerce Store
      </Button>
    </Stack>
  );

  if (firstLoading)
    return <CircularLoading loading={isFetching} className={classes.loading} />;

  return (
    <Stack flex={1} width='100%' overflow='hidden'>
      {headerComponent}
      <Stack className='customized-scrollbar' flex={1} overflow='auto'>
        <Table
          columns={columns}
          dataProps={isEmptyState ? EmptyStateData : data}
          loading={isFetching}
          onRowClick={isEmptyState ? undefined : onRowClick}
          clickableRowId={clickableRowId}
          sortBy={convertedSortParams(sort, sort_by as string)}
          onSort={handleSort}
          customEmptyState={isEmptyState ? renderSetupStore() : undefined}
          noResultText={null}
          sxNoData={{ padding: '35px !important' }}
          containerSxProps={{
            ...(isEmptyState
              ? {
                  '& td': {
                    'filter': 'grayscale(1)',
                    'opacity': 0.25,
                    '&:nth-child(3)': {
                      pointerEvents: 'none',
                    },
                    '& p': {
                      color: 'black !important',
                    },
                  },
                }
              : {}),
          }}
        />
      </Stack>

      <StyledPanelDetailContainer isOpen={!!storeId} fixedWidth={650}>
        {storeId && (
          <StoreDetail storeData={storeData} isFetching={isFetching} />
        )}
      </StyledPanelDetailContainer>
      {isOpenAddStore && <AddStore onClose={() => setIsOpenAddStore(false)} />}
    </Stack>
  );
};

export default withStyles(style)(StoreList);

import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseProps } from '@mui/material';
import Visibility from '~/assets/images/icons/visibility.svg';
import VisibilityOff from '~/assets/images/icons/visibility-off.svg';
import { SxProps, Theme } from '@mui/material/styles';
import { Input } from '../Input';

const DEFAULT_BLOCKED_CHARACTERS = ['e', 'E', '-', '+'];
export interface TextInputProps {
  classNameHelperText?: string;
  classNameContainer?: string;
  legend?: React.ReactNode | string;
  defaultValue?: string;
  value?: string;
  className?: string;
  fullWidth?: boolean;
  error?: boolean;
  success?: boolean;
  multiline?: boolean;
  maxLength?: number;
  helperText?: string | React.ReactNode | '';
  inputProps?: InputBaseProps['inputProps'];
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'url' | 'time';
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement | null>;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  id?: string;
  name?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  rows?: number;
  enableCustomHelperText?: boolean;
  sxLabel?: SxProps;
  disableValueMutationByArrowKeys?: boolean;
  blockedCharacters?: string[];
  onChange?: (value: any) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClick?: MouseEventHandler<any> | undefined;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const TextInput = React.forwardRef(
  (props: TextInputProps, ref: React.Ref<HTMLInputElement | any>) => {
    const {
      classNameHelperText,
      classNameContainer,
      legend,
      fullWidth = true,
      error,
      success,
      helperText,
      maxLength,
      inputProps,
      prefix,
      suffix,
      type = 'text',
      multiline = false,
      inputRef,
      placeholder,
      rows,
      name,
      id,
      disabled = false,
      disableValueMutationByArrowKeys,
      blockedCharacters = DEFAULT_BLOCKED_CHARACTERS,
      sxLabel,
      onKeyDown,
      onChange,
      onClick,
      onBlur,
      ...inputComponentProps
    } = props;
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const startAdornmentElement = prefix && (
      <InputAdornment position='start'>{prefix}</InputAdornment>
    );

    let endAdornmentElement = suffix && (
      <InputAdornment position='end' variant='filled'>
        {suffix}
      </InputAdornment>
    );

    if (type === 'password') {
      endAdornmentElement = (
        <InputAdornment position='end' variant='filled'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={(event) => {
              setShowPassword(!showPassword);
              event.preventDefault();
            }}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
            }}
          >
            {showPassword ? (
              <img src={Visibility} width={20} height={20} alt='visibility' />
            ) : (
              <img
                src={VisibilityOff}
                width={20}
                height={20}
                alt='visibility-off'
              />
            )}
          </IconButton>
        </InputAdornment>
      );
    }

    let numberInputHandler = {};
    if (type === 'number') {
      numberInputHandler = {
        onKeyDown: (e) => {
          if (
            disableValueMutationByArrowKeys &&
            (e.key === 'ArrowUp' || e.key === 'ArrowDown')
          ) {
            e.preventDefault();
          }
          if (blockedCharacters.length && blockedCharacters.includes(e.key)) {
            e.preventDefault();
          }
        },
        // Disable paste negative value
        onPaste: async (e) => {
          e.preventDefault();
          const text = await navigator.clipboard.readText();
          const regexNumber = /^(\d*\.)?\d+$/;
          if (!regexNumber.test(text)) {
            e.preventDefault();
          }
        },
        step: 'any',
      };
    }

    const convertCustomErrorMsg = (customHelperText, value) => {
      const errorArray = customHelperText.split(':');
      if (errorArray.length !== 2) return helperText;
      switch (errorArray[0]) {
        case 'invalidMaxLength':
          return `Max ${errorArray[1]} characters (${value?.length}/${errorArray[1]})`;
        default:
          return helperText;
      }
    };

    return (
      <FormControl
        ref={ref}
        error={error}
        fullWidth={fullWidth}
        variant='standard'
        className={classNameContainer}
      >
        {legend &&
          (typeof legend === 'string' ? (
            <FormLabel sx={sxLabel}>{legend}</FormLabel>
          ) : (
            legend
          ))}
        <Input
          {...inputComponentProps}
          disabled={disabled}
          inputRef={inputRef}
          multiline={multiline}
          error={error}
          success={success}
          type={showPassword ? 'text' : type}
          placeholder={placeholder}
          inputProps={{
            'aria-label': typeof legend === 'string' ? legend : 'input-label',
            maxLength,
            'autoComplete': 'off',
            'data-testid': `${id}-input`,
            ...numberInputHandler,
            ...inputProps,
          }}
          rows={rows}
          startAdornment={startAdornmentElement}
          endAdornment={endAdornmentElement}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          name={name}
        />
        {helperText && (
          <FormHelperText
            error={error}
            className={clsx(classNameHelperText, success && 'success')}
            data-testid={`${id}-input-error`}
          >
            {props.enableCustomHelperText
              ? convertCustomErrorMsg(props.helperText, inputProps.value)
              : helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

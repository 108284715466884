import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { alertParams, showAlert } from '~/components/common/Alert';
import Table from '~/components/common/Table';
import { TColumn } from '~/components/common/Table/Table';
import { UserStatus, IUser } from '~/models/user';
import { styled } from '~/themes';
import { getNewSort, transformTypeToTitle } from '~/utils/common';
import {
  formatPhoneNumber,
  updateLocationSearchParams,
} from '~/utils/formatter';
import { IAlert } from '~/components/common/Alert/Alert';
import { queryClient } from '~/lib/react-query';
import {
  addToQueryString,
  fromQueryString,
  omitFromQueryString,
} from '~/utils/queryString';
import { CustomerSupportTableLabels, ICustomerSupport } from '~/models/driver';
import { LinkButton } from '~/components/common/Button';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { Stack } from '@mui/system';
import { DriverAvatar } from '~/components/shared/AssignTask/DriverAvatar';
import { Typography } from '@mui/material';
import { ToolTipListText } from '~/components/common/Tooltip/TooltipListText';
import { IDriver } from '../types';
import {
  driverSettingsQueryKeys,
  useDeleteDriver,
  useResendInvitationDriver,
} from '../apis';

const EmptyStateData = [
  {
    first_name: 'John',
    last_name: 'Doe',
    display_name: 'John Doe',
    phone: '+11010101010',
    role: 'driver',
    default: false,
    status: 'active',
    driver_status: 'online',
    customer_support: ICustomerSupport.ContactDriverPhone,
    dob: null,
    teams: [
      {
        name: 'Day Shift',
        description: 'Day Shift',
        hub_id: null,
        id: '65113e8f1693da22d530739a',
      },
    ],
    id: '6549b858f79b38b8e8f462a1',
  },
  { id: '_noData' },
];

export const StyledDriverStatus = styled('span', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: UserStatus }>(({ status, theme }) => ({
  ...(status === UserStatus.Pending && {
    color: theme.color.yellow,
  }),
  ...(status === UserStatus.Active && {
    color: theme.color.green,
  }),
}));

interface IDriverTableProps {
  data: IDriver[];
  isLoading: boolean;
  isEmptyState?: boolean;
  renderEmpty?: React.ReactNode;
}

export const DriverTable: React.FC<IDriverTableProps> = ({
  data,
  isLoading,
  isEmptyState,
  renderEmpty,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isStarterOrStandardPlan } = usePaymentInfo();
  const sourceType =
    useParams<{ sourceType: string; source: string }>()?.sourceType ||
    UserStatus.Active;
  const { id: driverId, sort, sort_by } = fromQueryString(location.search);

  const { mutate: deleteUser, isLoading: loadingUpdate } = useDeleteDriver({
    onSuccess: () => {
      showAlert({
        ...alertParams.success,
        title: 'Driver was deleted successfully',
      });
      queryClient.invalidateQueries(driverSettingsQueryKeys.getDriverList());
    },
  });

  const { mutate: resendInvitation, isLoading: loadingResent } =
    useResendInvitationDriver({
      onSuccess: () => {
        showAlert({
          ...alertParams.success,
          title: 'Resent Invitation Successfully',
          description:
            'The invitation SMS has been resent to the driver’s phone.',
        });
      },
    });

  const handleSort = useCallback(
    (sortId: string, nextArrange: number) => {
      const sortParams = getNewSort(sortId, nextArrange);
      navigate({
        pathname: location.pathname,
        search: `?${updateLocationSearchParams(location, sortParams)}`,
      });
    },
    [location],
  );

  const onDeleteAlert = useCallback(
    (id: string) => {
      let params: IAlert = {
        ...alertParams.warning,
        title: 'Warning',
        cancelText: 'Cancel',
        okText: 'Yes, Delete',
        onOk: () => deleteUser({ id }),
      };
      params = {
        ...params,
        description:
          'You are about to delete this driver. This will affect tasks with the driver assigned. Are you sure you want to continue?',
      };
      showAlert(params);
    },
    [sourceType],
  );

  const renderAction = useCallback(
    ({ id, status, display_name }: IUser) => (
      <>
        {(status === UserStatus.Pending || isEmptyState) && (
          <>
            <LinkButton
              onClick={(e) => {
                resendInvitation({ id });
                e.stopPropagation();
              }}
            >
              Resend Invitation
            </LinkButton>
            <span role='presentation'>{'  |  '}</span>
          </>
        )}
        <LinkButton
          data-value={display_name}
          onClick={(e) => {
            onDeleteAlert(id);
            e.stopPropagation();
          }}
        >
          Delete
        </LinkButton>
      </>
    ),
    [sourceType, isEmptyState],
  );

  const renderStatus = useCallback(
    ({ status, display_name }: IDriver) => (
      <StyledDriverStatus
        role='presentation'
        data-testid={`${display_name}-status`}
        status={status}
      >
        {transformTypeToTitle(status)}
      </StyledDriverStatus>
    ),
    [sourceType],
  );

  const renderAvatar = (item: IUser) => (
    <Stack direction='row' alignItems='center'>
      <DriverAvatar size={40} avatar={item.avatar} name={item.display_name} />
      <Typography data-testid='display-name-driver' ml={1}>
        {item?.display_name}
      </Typography>
    </Stack>
  );

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'display_name',
        title: 'Driver',
        cellStyle: { width: 190 },
        sortable: true,
        renderComponent: renderAvatar,
      },
      {
        id: 'status',
        title: 'Status',
        cellStyle: { minWidth: 120 },
        sortable: true,
        renderComponent: renderStatus,
      },
      {
        id: 'phone',
        title: 'Contact Number',
        cellStyle: { minWidth: 120 },
        sortable: false,
        renderComponent: ({ phone }: IUser) =>
          isEmptyState ? '(+1) 101-010-1010' : formatPhoneNumber(phone),
      },
      {
        id: 'team_names',
        title: 'Teams',
        cellStyle: { minWidth: 120, maxWidth: 500 },
        sortable: false,
        renderComponent: ({ teams }: IUser) => {
          if (isStarterOrStandardPlan && !isEmptyState) return '-';
          return (
            <ToolTipListText list={teams.map((team) => team.name)} max={3} />
          );
        },
      },
      {
        id: 'customer_support',
        title: 'Customer Support Option',
        cellStyle: { minWidth: true },
        sortable: true,
        renderComponent: (item) =>
          isEmptyState
            ? 'Customers can only contact the driver’s phone number'
            : CustomerSupportTableLabels[item.customer_support] ||
              CustomerSupportTableLabels[ICustomerSupport.ContactDriverPhone],
      },
      {
        id: 'action',
        title: 'Action',
        cellStyle: {
          minWidth: 120,
          textAlign: 'right',
        },
        renderComponent: renderAction,
      },
    ],
    [sourceType, isStarterOrStandardPlan, isEmptyState],
  );

  const clickableRowId = useMemo(
    () => driverId && data.findIndex((x) => x.id === driverId),
    [data, driverId],
  );

  const onRowClick = useCallback(
    (item: any) => {
      let search;
      if (item.id === driverId) {
        search = omitFromQueryString(location.search, ['id']);
      } else {
        search = addToQueryString(location.search, {
          id: item.id,
        });
      }
      navigate({
        pathname: location.pathname,
        search,
      });
    },
    [location],
  );

  return (
    <Table
      columns={columns}
      dataProps={isEmptyState ? EmptyStateData : data || []}
      loading={isLoading || loadingUpdate || loadingResent}
      sortBy={Number(sort) ? { [sort_by as string]: Number(sort) } : {}}
      onSort={handleSort}
      clickableRowId={clickableRowId}
      onRowClick={isEmptyState ? undefined : onRowClick}
      noResultText={null}
      customEmptyState={isEmptyState ? renderEmpty : undefined}
      sxNoData={{ padding: '35px !important' }}
      containerSxProps={{
        ...(isEmptyState
          ? {
              '& td': {
                'filter': 'grayscale(1)',
                'opacity': 0.25,
                '&:last-child': {
                  pointerEvents: 'none',
                },
                '& span': {
                  color: 'black !important',
                },
              },
            }
          : {}),
      }}
    />
  );
};

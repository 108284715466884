/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import Modal from '~/components/common/Modal';
import themes, { styled } from '~/themes';
import { Icon } from '~/components/common/Icon';
import { TextLink } from '~/components/common/TextLink';
import { useUploadTasks, useGetTaskUploadedInfo } from '~/services/api/task';
import { showAlert, alertParams } from '~/components/common/Alert';
import { DropState, useDrop } from '~/hooks/useDrop';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { importFileUrl } from '~/constants/task';

type ImportTaskProps = {
  open: boolean;
  setOpen: (v: boolean) => void;
};
export enum ImportTaskStep {
  ChooseFile = 'choose-file',
  ErrorImportTask = 'errorImportTask',
  Importing = 'importing',
}

export const StyledDropZone = styled('div', {
  shouldForwardProp: (props) => props !== 'dropState',
})<{ dropState?: string }>(({ dropState }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(240,240,240,0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'visibility ease-in 0.1s',
  visibility:
    dropState === DropState.DraggingOver || dropState === DropState.DragEnter
      ? 'visible'
      : 'hidden',
}));

export const ImportTask: React.FC<ImportTaskProps> = ({ open, setOpen }) => {
  const [step, setStep] = useState<ImportTaskStep>(ImportTaskStep.ChooseFile);
  const [uploadTaskId, setUploadTaskId] = useState<string>('');

  const inputFileRef = useRef<HTMLInputElement>();

  const { data } = useGetTaskUploadedInfo({
    id: uploadTaskId,
    onSuccess: ({ status, success_info }) => {
      if (status === 'importing') {
        return setStep(ImportTaskStep.Importing);
      }
      if (status === 'success') {
        setOpen(false);
        queryClient.invalidateQueries([queryKeys.orgManagement.org]);
        showAlert({
          ...alertParams.success,
          title: 'Tasks Imported',
          description: success_info,
        });

        return setUploadTaskId('');
      }

      if (status === 'failed') {
        return setStep(ImportTaskStep.ErrorImportTask);
      }
      return true;
    },
  });

  const { mutate: uploadTaskMutation, isLoading: isLoadingUploadTask } =
    useUploadTasks({
      onSuccess: ({ id }) => {
        setUploadTaskId(id);
      },
    });

  const failInfo: Array<{ rowId: string; messages: string[] }> = useMemo(
    () =>
      data?.failed_info?.map((d) => ({
        rowId: d.length ? d[0] : '',
        messages: d.length ? d[1] : [''],
      })),
    [data.failed_info],
  );

  const handleImportFile = (files: FileList) => {
    const fileMBSize = files[0].size / 1024 / 1024;
    const fileType = files[0].type;
    if (files.length > 1) {
      return showAlert({
        ...alertParams.warning,
        description:
          'You are attempting to upload multiple files.\n The system only accepts one .csv file.',
      });
    }
    if (fileType !== 'text/csv') {
      return showAlert({
        ...alertParams.warning,
        description: 'The file is not in .csv format.',
      });
    }
    if (fileMBSize >= 10) {
      return showAlert({
        ...alertParams.warning,
        title: 'File Size Exceeds Maximum Limit',
        description: 'Please re-upload file (max 10 MB)',
      });
    }

    const formData = new FormData();
    formData.append('tasks_upload_file', files[0]);
    return uploadTaskMutation(formData);
  };

  const { dropFuncProps, dropState } = useDrop({
    onDropped: async (fileData) => {
      handleImportFile(fileData);
      inputFileRef.current.value = null;
    },
  });

  const renderContent = useMemo(() => {
    switch (step) {
      case ImportTaskStep.ChooseFile:
        return (
          <>
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{
                position: 'relative',
                height: 350,
              }}
              {...dropFuncProps}
            >
              <Stack
                alignItems='center'
                sx={{
                  '& > input': {
                    display: 'none',
                  },
                  '& > label': {
                    textAlign: 'center',
                    width: 130,
                    cursor: 'pointer',
                    background: themes.bg.midPurple,
                    padding: themes.spacing(0.8, 0),
                    borderRadius: '18px',
                    color: themes.color.violet900,
                    fontWeight: 'bold',
                  },
                }}
              >
                <Icon
                  name='file-upload'
                  useBackgroundImg
                  style={{ width: 75, height: 100 }}
                />
                <Typography
                  mt={themes.spacing(1.5)}
                  mb={themes.spacing(2)}
                  whiteSpace='break-spaces'
                  textAlign='center'
                >
                  {
                    'Choose a CSV file (max 10 MB) to import tasks from.\n No more than 5,000 tasks per file'
                  }
                </Typography>
                {isLoadingUploadTask ? (
                  <CircularProgress size={24} />
                ) : (
                  <label htmlFor='csv-file-select'>Choose File</label>
                )}
                <input
                  ref={inputFileRef}
                  id='csv-file-select'
                  type='file'
                  multiple={false}
                  // accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  accept='.csv'
                  onChange={(e) => {
                    handleImportFile(e.target.files);
                    inputFileRef.current.value = null;
                  }}
                />
              </Stack>
            </Stack>
            <Box
              sx={{
                background: themes.bg.lightPurple,
                borderRadius: '10px',
                padding: 2,
                textAlign: 'center',
              }}
            >
              View{' '}
              <TextLink to={importFileUrl.file}>
                the sample import file
              </TextLink>{' '}
              and follow{' '}
              <TextLink
                target='_blank'
                rel='noreferrer'
                to={importFileUrl.instruction}
              >
                the detailed instructions.
              </TextLink>{' '}
              <Typography>
                Please make sure you input all required information before
                uploading your file.
              </Typography>
            </Box>
          </>
        );
      case ImportTaskStep.ErrorImportTask:
        return (
          <>
            <Stack direction='row'>
              <Icon
                name='exclamation'
                size={20}
                style={{ marginRight: 10, color: '#D62A28' }}
                useBackgroundImg
              />
              <Typography
                sx={{
                  '& > span': {
                    fontWeight: 'bold',
                    color: '#D62A28',
                  },
                }}
              >
                <span>
                  {failInfo.length}{' '}
                  {failInfo?.length === 1 ? 'issue' : 'issues'}
                </span>{' '}
                were found when importing. Please correct the issues and
                re-upload the file.
              </Typography>
            </Stack>
            <Stack
              sx={{
                border: '1px solid #E9E7F6',
                borderRadius: '5px',
                mt: 2,
                maxHeight: 400,
                overflow: 'hidden',
              }}
            >
              <Stack
                className='customized-scrollbar'
                sx={{ overflow: 'auto', padding: 2 }}
                spacing={1}
              >
                {failInfo.map(({ rowId, messages }, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Stack key={idx}>
                    <Typography>{`Row ${rowId + 1}`}</Typography>
                    {messages.map((msg: string) => (
                      <React.Fragment key={msg}>
                        <Typography mt={0.5} color='#D62A28'>
                          {msg || '-'}
                        </Typography>
                      </React.Fragment>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </>
        );

      case ImportTaskStep.Importing:
        return (
          <Stack
            sx={{ height: 370 }}
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress
              size={80}
              sx={{ mb: 3, color: themes.color.violet600 }}
              thickness={5}
            />
            <Typography
              fontWeight='bold'
              fontSize={18}
              color={themes.color.violet900}
            >
              Importing...
            </Typography>
            <Typography sx={{ mt: 2 }}>{data.progress}</Typography>
          </Stack>
        );
      default:
        return <div />;
    }
  }, [step, isLoadingUploadTask, data?.progress, dropState]);

  const modelTitle = useMemo(() => {
    if (step === ImportTaskStep.Importing) return '';
    return 'Import Tasks';
  }, [step]);

  const modelActions = useMemo(() => {
    if (step === ImportTaskStep.Importing || step === ImportTaskStep.ChooseFile)
      return [];
    return [
      {
        title: 'Cancel',
        onClick: () => setOpen(false),
        buttonType: 'default',
      },
      {
        title: 'Re-upload',
        buttonType: 'primary',
        onClick: () => {
          setUploadTaskId('');
          setStep(ImportTaskStep.ChooseFile);
        },
      },
    ];
  }, [step]);

  const modelWidth = useMemo(() => {
    if (step === ImportTaskStep.Importing) return 480;
    return 650;
  }, [step]);

  return (
    <Modal
      disableCloseOutside
      actions={modelActions}
      PaperProps={{
        sx: {
          maxWidth: modelWidth,
        },
      }}
      title={modelTitle}
      open={open}
      onClose={() => setOpen(false)}
    >
      {renderContent}
    </Modal>
  );
};

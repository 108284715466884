import { GA4_ID, GTAG_ID } from '~/config';

export const addGoogleTagScript = () => {
  if (!GA4_ID) return;
  const gaLoadScript = document.createElement('script');
  gaLoadScript.async = true;
  gaLoadScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`;
  document.head.appendChild(gaLoadScript);

  const gaPushScript = document.createElement('script');
  gaPushScript.text = `
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'gtm.blocklist': ['bzi']
    });
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${GA4_ID}');`;
  document.head.appendChild(gaPushScript);
};

export const addGoogleTagCustomScript = () => {
  if (!GTAG_ID) return;
  const gaLoadScript = document.createElement('noscript');
  gaLoadScript.textContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTAG_ID}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(gaLoadScript);

  const gaPushScript = document.createElement('script');
  gaPushScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${GTAG_ID}');`;
  document.head.appendChild(gaPushScript);
};

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import Modal from '~/components/common/Modal';
import { IntegrationType } from '~/models/stores';
import { Stack } from '@mui/system';
import themes from '~/themes';
import Button from '~/components/common/Button';
import { getPlatformIcon } from '~/utils/settings';
import { useCreateIntegration } from '~/services/api/stores';
import { LOCAL_STORAGE_KEYS, clearItem } from '~/utils/localStorage';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { StyledHeaderTitle } from './style';
import { BreadstackStore } from './components/BreadstackStore';
import { ShopifyStore } from './components/ShopifyStore';
import { WooCommerceStore } from './components/WooCommerceStore';
import { AddStoreLayout } from './layout/AddStoreLayout';

type AddStoreProps = { onClose: () => void };

export const AddStore: React.FC<AddStoreProps> = ({ onClose }) => {
  const [platform, setPlatform] = useState<IntegrationType>(null);
  const [platformSelect, setPlatformSelect] = useState<IntegrationType>(
    IntegrationType.Woo,
  );
  const [step, setStep] = useState<number>(1);
  const isEnabledFlagShopifyIntegration = useFeatureIsOn('shopify-integration');

  const {
    mutate: createIntegrationMutation,
    isLoading: isLoadingCreateIntegration,
  } = useCreateIntegration({
    onSuccess: (resp) => {
      queryClient.invalidateQueries([queryKeys.stores.getListIntegration]);
      if (resp.integration_type === IntegrationType.Shopify) {
        clearItem(LOCAL_STORAGE_KEYS.SHOPIFY_REDIRECT_STORE_URL);
        window.location.href = resp.response_url;
      } else {
        onClose();
      }
    },
  });
  const platformOptions = [
    {
      label: 'Breadstack',
      value: IntegrationType.Breadstack,
    },
    {
      label: 'Shopify',
      value: IntegrationType.Shopify,
    },

    {
      label: 'WooCommerce',
      value: IntegrationType.Woo,
    },
  ].filter((o) => {
    if (isEnabledFlagShopifyIntegration) {
      return o;
    }
    return o.value !== IntegrationType.Shopify;
  });
  const totalStep = useMemo(() => {
    if (platformSelect !== IntegrationType.Breadstack) {
      return 4;
    }
    return 3;
  }, [platformSelect]);

  const bodyComponent = useMemo(() => {
    switch (platform) {
      case IntegrationType.Breadstack:
        return (
          <BreadstackStore
            step={step}
            setStep={setStep}
            setPlatform={setPlatform}
            onClose={onClose}
            totalStep={totalStep}
            createIntegrationMutation={createIntegrationMutation}
            isLoadingCreateIntegration={isLoadingCreateIntegration}
          />
        );

      case IntegrationType.Shopify:
        return (
          <ShopifyStore
            step={step}
            setStep={setStep}
            setPlatform={setPlatform}
            onClose={onClose}
            totalStep={totalStep}
            createIntegrationMutation={createIntegrationMutation}
            isLoadingCreateIntegration={isLoadingCreateIntegration}
          />
        );
      case IntegrationType.Woo:
        return (
          <WooCommerceStore
            step={step}
            setStep={setStep}
            setPlatform={setPlatform}
            onClose={onClose}
            totalStep={totalStep}
            createIntegrationMutation={createIntegrationMutation}
            isLoadingCreateIntegration={isLoadingCreateIntegration}
          />
        );
      default:
        return (
          <AddStoreLayout step={step} totalStep={totalStep} onClose={onClose}>
            <StyledHeaderTitle>Set Up Your Store</StyledHeaderTitle>
            <Typography>
              To start, select your store’s eCommerce platform
            </Typography>
            <RadioGroup
              sx={{ mt: themes.spacing(5), width: '100%' }}
              onChange={(e) => {
                setPlatformSelect(e.target.value as IntegrationType);
              }}
              value={platformSelect}
            >
              {platformOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  sx={{
                    '& > span:first-of-type': {
                      color: `${themes.color.violet900} !important`,
                    },
                    'border': '1px solid #E9E7F6',
                    'borderRadius': '10px',
                    'p': 1,
                    'mt': 1,
                    'ml': 0,
                    'width': '100%',
                    ...(option.value === platformSelect && {
                      background: themes.bg.midPurple,
                    }),
                  }}
                  value={option.value}
                  control={<Radio />}
                  label={
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <img
                        width={35}
                        height={35}
                        src={getPlatformIcon(option.value)}
                        alt='platform-icon'
                      />{' '}
                      <Typography fontWeight='bold' fontSize={16}>
                        {option.label}
                      </Typography>
                    </Stack>
                  }
                />
              ))}
            </RadioGroup>
            <Button
              onClick={() => {
                setStep((s) => s + 1);
                setPlatform(platformSelect);
              }}
              sx={{ mt: 12, width: 150 }}
            >
              Next
            </Button>
          </AddStoreLayout>
        );
    }
  }, [platform, platformSelect, step, totalStep, isLoadingCreateIntegration]);

  return (
    <Modal
      disableCloseOutside
      maxWidth='md'
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          '& .MuiDialogContent-root': {
            p: 0,
          },
        },
      }}
    >
      {bodyComponent}
    </Modal>
  );
};

import { Box, Stack, Typography } from '@mui/material';
import themes, { styled } from '~/themes';
import Button, { CloseButton } from '~/components/common/Button';
import Modal from '~/components/common/Modal';
import { useCallback, useMemo, useState } from 'react';
import { Field } from '~/components/common/Formik';
import BreadstackLogo from '~/assets/images/icons/breadstack-logo.svg';
import {
  useCheckConnection,
  useGetBreadstackStores,
} from '~/services/api/stores';
import { showAlert, alertParams } from '~/components/common/Alert';
import { getErrorMessage } from '~/components/common/Alert/alertParams';
import { Form, Formik } from 'formik';
import { BreadstackLoginSchema } from '~/utils/schema/stores';
import RadioButton from '~/components/common/RadioButton';
import { TextInput } from '~/components/common/TextInput';
import {
  ConnectionStoreStatus,
  CreateStoreStatus,
  IntegrationType,
} from '~/models/stores';
import { TextLink } from '~/components/common/TextLink';
import { CanFleetPluginURL } from '~/constants/stores';
import { Icon } from '~/components/common/Icon';
import { StyledHeaderTitle } from '../style';
import { StoreConnection } from './StoreConnection';
import { AddStoreLayout } from '../layout/AddStoreLayout';

type BreadstackStoreProps = {
  step: number;
  setStep: (v: number) => void;
  setPlatform: (p: IntegrationType) => void;
  onClose: () => void;
  totalStep: number;
  createIntegrationMutation: any;
  isLoadingCreateIntegration: boolean;
};

export const StyledTextField = styled(Field.TextInput)(() => ({
  'backgroundColor': '#EDF1FB',
  'borderRadius': 5,
  'minHeight': 35,
  'paddingRight': 5,
  '.MuiInputBase-input': {
    'boxShadow': 'none !important',
    'color': '#2E2E2E',
    '&::placeholder': {
      opacity: 1,
      color: '#74798C',
      fontSize: 14,
    },
  },
  '&, &:not(.Mui-error):hover': {
    '.MuiOutlinedInput-notchedOutline': {
      border: '0.25px solid #2959C5',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  'marginTop': '30px !important',
  'background': theme.bg.breadstack,
  'width': 'fit-content',
  '&:hover': {
    backgroundColor: `${theme.bg.breadstack} !important`,
  },
  '&.loading': {
    backgroundColor: `${theme.bg.breadstack} !important`,
  },
}));

export const BreadstackStore: React.FC<BreadstackStoreProps> = ({
  step,
  setStep,
  setPlatform,
  onClose,
  totalStep,
  createIntegrationMutation,
  isLoadingCreateIntegration,
}) => {
  const [open, setOpen] = useState(false);
  const [bsStoreSelect, setBsStoreSelect] = useState<{
    store_id: string;
    store_url: string;
    display_name: string;
  }>(null);

  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStoreStatus>(null);

  const {
    mutate: checkConnectionMutation,
    isLoading: isLoadingCheckConnection,
  } = useCheckConnection({
    onSuccess: (resp) => {
      if (resp?.connected) {
        setConnectionStatus(ConnectionStoreStatus.Connected);
      } else {
        setConnectionStatus(ConnectionStoreStatus.NotConnected);
      }
    },
    onError: () => setConnectionStatus(ConnectionStoreStatus.NotConnected),
  });

  const {
    mutate: getBreadstackStores,
    data: breadstackStores = [],
    isLoading,
  } = useGetBreadstackStores({
    onSuccess: (data) => {
      if (!data.length) {
        showAlert({
          ...alertParams.warning,
          description: 'No breadstack store data.',
        });
      }
    },
    onError: (error) => {
      showAlert({
        ...alertParams.warning,
        title: 'Error',
        description:
          getErrorMessage(error) || 'Could not connect to Breadstack',
      });
    },
  });

  const onCloseBsModal = () => {
    setBsStoreSelect(null);
    setOpen(false);
  };

  const handleSignInBs = (values) => {
    getBreadstackStores({
      email: values.email,
      password: values.password,
    });
  };

  const onDataChange = (_, newValue: string) => {
    const selectedItem = (breadstackStores || []).find(
      (x) => x.store_url === newValue,
    );
    setBsStoreSelect({
      store_id: selectedItem?.store_id || '',
      store_url: selectedItem?.store_url || '',
      display_name: selectedItem?.store_name || '',
    });
  };
  const handleCreateIntegration = useCallback(() => {
    onClose();
    createIntegrationMutation({
      display_name: bsStoreSelect?.display_name,
      status: CreateStoreStatus?.Deactivated,
      integration_type: IntegrationType.Breadstack,
      store_url: bsStoreSelect?.store_url,
      store_id: bsStoreSelect?.store_id,
    });
  }, [bsStoreSelect, connectionStatus]);

  const loginComponent = useMemo(
    () => (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSignInBs}
        validationSchema={BreadstackLoginSchema}
      >
        {() => (
          <Form style={{ minWidth: 300 }}>
            <Stack spacing={2} mt={2} alignItems='center'>
              <Typography textAlign='center'>
                Log in to Breadstack to continue.
              </Typography>
              <StyledTextField name='email' legend='Email' type='email' />
              <StyledTextField
                name='password'
                legend='Password'
                type='password'
              />
              <StyledButton type='submit' loading={isLoading}>
                Sign in
              </StyledButton>
            </Stack>
          </Form>
        )}
      </Formik>
    ),
    [isLoading],
  );

  const storeListComponent = useMemo(
    () => (
      <>
        <Typography textAlign='center' my={2}>
          Select a store to connect with Breadstack Delivery.
        </Typography>
        <Box
          className='customized-scrollbar'
          maxHeight={300}
          sx={{ overflow: 'auto' }}
        >
          <RadioButton
            name='store_url'
            options={breadstackStores
              ?.map((item) => ({
                value: item.store_url,
                label: item.store_name,
                desc: item.store_url,
              }))
              .sort((a, b) =>
                a?.label?.toUpperCase().localeCompare(b?.label?.toUpperCase()),
              )}
            onChange={onDataChange}
          />
        </Box>
        <StyledButton
          onClick={() => {
            setStep(3);
            setOpen(false);
          }}
        >
          Connect
        </StyledButton>
      </>
    ),
    [breadstackStores],
  );

  const contentComponent = useMemo(() => {
    if (step === 2) {
      return (
        <>
          <StyledHeaderTitle>Log into Breadstack</StyledHeaderTitle>
          <Typography>
            Log into your Breadstack account and select a store to connect
          </Typography>
          <Stack
            sx={{
              background: themes.bg.lightPurple,
              borderRadius: '50%',
              width: 200,
              height: 200,
              justifyContent: 'center',
              alignItems: 'center',
              mt: 5,
              mb: 12,
            }}
          >
            <Icon name='link' size={80} useBackgroundImg />
          </Stack>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              '& > button': {
                width: 160,
              },
            }}
          >
            <Button
              buttonType='default'
              onClick={() => {
                setStep(1);
                setPlatform(null);
              }}
            >
              Back
            </Button>
            <Button onClick={() => setOpen(true)}>Log In</Button>
          </Stack>
        </>
      );
    }
    return (
      <>
        <StyledHeaderTitle>
          {connectionStatus === ConnectionStoreStatus.Connected
            ? 'Setup Completed!'
            : 'Connect with Breadstack'}
        </StyledHeaderTitle>
        <Typography mb={8}>
          {connectionStatus === ConnectionStoreStatus.Connected
            ? 'Your store is set up successfully'
            : 'Check the connection with your eCommerce store'}
        </Typography>
        <TextInput
          legend='Store URL'
          value={bsStoreSelect?.store_url}
          disabled
        />

        <StoreConnection status={connectionStatus} />
        {connectionStatus !== ConnectionStoreStatus.Connected && (
          <Typography sx={{ width: '100%', mb: 15 }}>
            Make sure to{' '}
            <TextLink
              style={{ fontWeight: 'bold' }}
              to={CanFleetPluginURL.Breadstack}
            >
              install Breadstack Delivery plugin
            </TextLink>
            .
          </Typography>
        )}

        {connectionStatus === ConnectionStoreStatus.Connected ? (
          <Button
            sx={{ width: 150 }}
            onClick={() => {
              createIntegrationMutation({
                display_name: bsStoreSelect?.display_name,
                status: CreateStoreStatus?.Active,
                integration_type: IntegrationType.Breadstack,
                store_url: bsStoreSelect?.store_url,
                store_id: bsStoreSelect?.store_id,
              });
            }}
            loading={isLoadingCreateIntegration}
          >
            Done
          </Button>
        ) : (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              '& > button': {
                width: 180,
              },
            }}
          >
            <Button
              buttonType='default'
              loading={isLoadingCheckConnection || isLoadingCreateIntegration}
              onClick={handleCreateIntegration}
            >
              Do It Later
            </Button>
            <Button
              onClick={() => {
                setConnectionStatus(ConnectionStoreStatus.Checking);
                checkConnectionMutation({
                  integration_type: IntegrationType.Breadstack,
                  store_url: bsStoreSelect?.store_url,
                  store_id: bsStoreSelect?.store_id,
                });
              }}
              loading={isLoadingCheckConnection}
            >
              Check Connection
            </Button>
          </Stack>
        )}
      </>
    );
  }, [
    step,
    isLoadingCheckConnection,
    isLoadingCreateIntegration,
    connectionStatus,
    bsStoreSelect,
  ]);

  return (
    <AddStoreLayout
      isEnabledCloseAndUpdateStore={!!bsStoreSelect?.store_url}
      onCloseAndUpdateStore={handleCreateIntegration}
      onClose={onClose}
      step={step}
      totalStep={totalStep}
    >
      {contentComponent}
      <Modal
        disableCloseOutside
        open={open}
        onClose={onCloseBsModal}
        PaperProps={{
          sx: {
            'padding': 3,
            'paddingBottom': 2,
            '& .MuiDialogContent-root': {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              overflow: 'hidden',
            },
          },
        }}
      >
        <CloseButton
          onClick={onCloseBsModal}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        />
        <img src={BreadstackLogo} alt='breadstack' width={170} height={25} />
        {breadstackStores.length ? storeListComponent : loginComponent}
      </Modal>
    </AddStoreLayout>
  );
};
